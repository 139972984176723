:root {
    --sans-font: -apple-system, BlinkMacSystemFont, "Avenir Next", Avenir, "Nimbus Sans L", Roboto, Noto, "Segoe UI", Arial, Helvetica, "Helvetica Neue", sans-serif;
    --mono-font: Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    --base-fontsize: 1.15rem;
    --header-scale: 1.25;
    --line-height: 1.618;
    --bg: #fff;
    --accent-bg: #f5f7ff;
    --text: #212121;
    --text-light: #585858;
    --border: #d8dae1;
    --accent: #0d47a1;
    --accent-light: #90caf9;
    --code: #d81b60;
    --preformatted: #444;
    --marked: #ffdd33;
    --disabled: #efefef
}

@media (prefers-color-scheme: dark) {
    :root {
        --bg: #212121;
        --accent-bg: #2b2b2b;
        --text: #dcdcdc;
        --text-light: #ababab;
        --border: #666;
        --accent: #ffb300;
        --accent-light: #ffecb3;
        --code: #f06292;
        --preformatted: #ccc;
        --disabled: #111
    }

    img, video {
        opacity: .6
    }
}

html {
    font-family: var(--sans-font)
}

body {
    color: var(--text);
    background: var(--bg);
    font-size: var(--base-fontsize);
    line-height: var(--line-height);
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;
    max-width: 45rem;
    padding: 0 .5rem;
    overflow-x: hidden;
    word-break: break-word;
    overflow-wrap: break-word
}

header {
    background: var(--accent-bg);
    border-bottom: 1px solid var(--border);
    text-align: center;
    padding: 2rem .5rem;
    width: 100vw;
    position: relative;
    box-sizing: border-box;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw
}

header h1, header p {
    margin: 0
}

main {
    padding-top: 1.5rem
}

h1, h2, h3 {
    line-height: 1.1
}

nav {
    font-size: 1rem;
    line-height: 2;
    padding: 1rem 0
}

nav a {
    margin: 1rem 1rem 0 0;
    border: 1px solid var(--border);
    border-radius: 5px;
    color: var(--text) !important;
    display: inline-block;
    padding: .1rem 1rem;
    text-decoration: none;
    transition: .4s
}

nav a:hover {
    color: var(--accent) !important;
    border-color: var(--accent)
}

nav a.current:hover {
    text-decoration: none
}

footer {
    margin-top: 2rem;
    padding: 2rem 1rem 1.5rem 1rem;
    color: var(--text-light);
    font-size: .9rem;
    text-align: center;
    border-top: 1px solid var(--border)
}

h1 {
    font-size: calc(var(--base-fontsize) * var(--header-scale) * var(--header-scale) * var(--header-scale) * var(--header-scale));
    margin-top: calc(var(--line-height) * 1.5rem)
}

h2 {
    font-size: calc(var(--base-fontsize) * var(--header-scale) * var(--header-scale) * var(--header-scale));
    margin-top: calc(var(--line-height) * 1.5rem)
}

h3 {
    font-size: calc(var(--base-fontsize) * var(--header-scale) * var(--header-scale));
    margin-top: calc(var(--line-height) * 1.5rem)
}

h4 {
    font-size: calc(var(--base-fontsize) * var(--header-scale));
    margin-top: calc(var(--line-height) * 1.5rem)
}

h5 {
    font-size: var(--base-fontsize);
    margin-top: calc(var(--line-height) * 1.5rem)
}

h6 {
    font-size: calc(var(--base-fontsize) / var(--header-scale));
    margin-top: calc(var(--line-height) * 1.5rem)
}

a, a:visited {
    color: var(--accent)
}

a:hover {
    text-decoration: none
}

[role=button], a button, button, input[type=button], input[type=reset], input[type=submit] {
    border: none;
    border-radius: 5px;
    background: var(--accent);
    font-size: 1rem;
    color: var(--bg);
    padding: .7rem .9rem;
    margin: .5rem 0;
    transition: .4s
}

[role=button][aria-disabled=true], a button[disabled], button[disabled], input[type=button][disabled], input[type=checkbox][disabled], input[type=radio][disabled], input[type=reset][disabled], input[type=submit][disabled], select[disabled] {
    cursor: default;
    opacity: .5;
    cursor: not-allowed
}

input:disabled, select:disabled, textarea:disabled {
    cursor: not-allowed;
    /*background-color: var(--disabled)*/
}

input[type=range] {
    padding: 0
}

abbr {
    cursor: help
}

[role=button]:focus, [role=button]:not([aria-disabled=true]):hover, button:enabled:hover, button:focus, input[type=button]:enabled:hover, input[type=button]:focus, input[type=checkbox]:enabled:hover, input[type=checkbox]:focus, input[type=radio]:enabled:hover, input[type=radio]:focus, input[type=reset]:enabled:hover, input[type=reset]:focus, input[type=submit]:enabled:hover, input[type=submit]:focus {
    filter: brightness(1.4);
    cursor: pointer
}

details {
    background: var(--accent-bg);
    border: 1px solid var(--border);
    border-radius: 5px;
    margin-bottom: 1rem
}

summary {
    cursor: pointer;
    font-weight: 700;
    padding: .6rem 1rem
}

details[open] {
    padding: .6rem 1rem .75rem 1rem
}

details[open] summary {
    margin-bottom: .5rem;
    padding: 0
}

details[open] > :last-child {
    margin-bottom: 0
}

table {
    border-collapse: collapse;
    width: 100%;
    margin: 1.5rem 0
}

td, th {
    border: 1px solid var(--border);
    text-align: left;
    padding: .5rem
}

th {
    background: var(--accent-bg);
    font-weight: 700
}

tr:nth-child(even) {
    background: var(--accent-bg)
}

table caption {
    font-weight: 700;
    margin-bottom: .5rem
}

ol, ul {
    padding-left: 3rem
}

input, select, textarea {
    font-size: inherit;
    font-family: inherit;
    padding: .5rem;
    margin-bottom: .5rem;
    color: var(--text);
    background: var(--bg);
    border: 1px solid var(--border);
    border-radius: 5px;
    box-shadow: none;
    box-sizing: border-box;
    width: 60%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

select {
    background-image: linear-gradient(45deg, transparent 49%, var(--text) 51%), linear-gradient(135deg, var(--text) 51%, transparent 49%);
    background-position: calc(100% - 20px), calc(100% - 15px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat
}

select[multiple] {
    background-image: none !important
}

input[type=checkbox], input[type=radio] {
    vertical-align: bottom;
    position: relative
}

input[type=radio] {
    border-radius: 100%
}

input[type=checkbox]:checked, input[type=radio]:checked {
    background: var(--accent)
}

input[type=checkbox]:checked::after {
    content: " ";
    width: .1em;
    height: .25em;
    border-radius: 0;
    position: absolute;
    top: .05em;
    left: .18em;
    background: 0 0;
    border-right: solid var(--bg) .08em;
    border-bottom: solid var(--bg) .08em;
    font-size: 1.8em;
    transform: rotate(45deg)
}

input[type=radio]:checked::after {
    content: " ";
    width: .25em;
    height: .25em;
    border-radius: 100%;
    position: absolute;
    top: .125em;
    background: var(--bg);
    left: .125em;
    font-size: 32px
}

textarea {
    width: 80%
}

@media only screen and (max-width: 720px) {
    input, select, textarea {
        width: 100%
    }
}

input[type=checkbox], input[type=radio] {
    width: auto
}

input[type=file] {
    border: 0
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0
}

hr {
    color: var(--border);
    border-top: 1px;
    margin: 1rem auto
}

mark {
    padding: 2px 5px;
    border-radius: 4px;
    background: var(--marked)
}

main img, main video {
    max-width: 100%;
    height: auto;
    border-radius: 5px
}

figure {
    margin: 0
}

figcaption {
    font-size: .9rem;
    color: var(--text-light);
    text-align: center;
    margin-bottom: 1rem
}

blockquote {
    margin: 2rem 0 2rem 2rem;
    padding: .4rem .8rem;
    border-left: .35rem solid var(--accent);
    opacity: .8;
    font-style: italic
}

cite {
    font-size: .9rem;
    color: var(--text-light);
    font-style: normal
}

code, kbd, pre, pre span, samp {
    font-size: 1.075rem;
    font-family: var(--mono-font);
    color: var(--code)
}

kbd {
    color: var(--preformatted);
    border: 1px solid var(--preformatted);
    border-bottom: 3px solid var(--preformatted);
    border-radius: 5px;
    padding: .1rem
}

pre {
    padding: 1rem 1.4rem;
    max-width: 100%;
    overflow: auto;
    overflow-x: auto;
    color: var(--preformatted);
    background: var(--accent-bg);
    border: 1px solid var(--border);
    border-radius: 5px
}

pre code {
    color: var(--preformatted);
    background: 0 0;
    margin: 0;
    padding: 0
}